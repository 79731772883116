@import "common/variables";

@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// common
@import "common/mixins";

// components
@import "components/animations";
@import "components/typography";
@import "components/background";
@import "components/grid";
@import "components/profile";
@import "components/list-group";
@import "components/button";

// template parts
@import "template-parts/skiplinks";
@import "template-parts/header";
@import "template-parts/footer";
@import "template-parts/agenda_modal";
@import "template-parts/sidebar";
@import "template-parts/create-new-item";

// template parts
@import "templates/agenda";
@import "templates/profile";
