.agenda {
	&__description {
		border-bottom: 1px solid $gray;
	}
	&__cards {
		&-card {
			&__right {
				margin-right: 0.5rem;
			}
			&__left {
				&-title {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				p {
					margin-top:    0;
					margin-bottom: 0;
				}
			}
			
			&:hover,
			&:focus {
				cursor: pointer;
				&__right,
				&__left {
					cursor: pointer;
				}
			}
			
			&:first-child {
				.list-group-item {
					border-bottom-right-radius: inherit;
					border-bottom-left-radius:  inherit;
				}
			}
			&:last-child {
				.list-group-item {
					border-bottom: 0;
				}
			}
		}
		
		
		
	}
}

.agenda__cards-card:hover {

}
