.sidebar-content {
	border-right: 1px solid $light-gray;
	-webkit-transition: margin 0.25s ease-out;
	-moz-transition: margin 0.25s ease-out;
	-o-transition: margin 0.25s ease-out;
	transition: margin 0.25s ease-out;
	padding-bottom: 1rem;

	display: flex;
	flex-flow: column;
	justify-content: space-between;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background: white;
	height: calc(100vh - 82px);

	@media screen and (min-width: $breakpoint-md) {
		position: relative;
		// min-height: calc(100vh - 162px);
		height: auto;
	}

	.navbar-brand {
		padding: 0.3125rem 1rem;
	}

	.list-group,
	.list-group-item{
		border: 0;
	}
	
	.list-group-item {
		position: relative;
		margin-top: .125rem;
		margin-bottom: .125rem;
		&:after {
			content: '';
			display: block;
			height: 0;
			width: 4px;
			position: absolute;
			right: -.75rem;
			top: 0;
			background: var(--bs-primary);
			transition: height ease .25s;
		}
		&:hover,
		&:focus {
			background-color: transparent;
			&:after {
				height: 100%;
			}
		}
	}

	.nav-item {
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
	.nav-link {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
.sidebar-toggle {
	-webkit-transition: all 0.25s ease-out;
	-moz-transition: all 0.25s ease-out;
	-o-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;

	margin-top: 0.375rem;
	margin-bottom: 0;
	margin-left: 0.375rem;
	padding-top: 1rem;
	padding-bottom: 1rem;

	position: absolute;
	z-index: 10;

	@media screen and (max-width: 767px) {
		top: 0 !important;
		bottom: auto !important;
	}

	@media screen and (min-width: $breakpoint-md) {
		margin-top: 0;
		position: relative;
		padding-left: var(--bs-gutter-x);
	}

	.btn {
		height: 35px;
		width: 35px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		-webkit-transition: transform 0.25s ease-out;
		-moz-transition: transform 0.25s ease-out;
		-o-transition: transform 0.25s ease-out;
		transition: transform 0.25s ease-out;

		&:hover {
			background-color: $light-gray;
		}
		.bi {
			color: $black;
		}
	}
}

.closed-toggle {
	.sidebar-toggle {
		margin-left: 0;
		padding-left: var(--bs-gutter-x);
		padding-right: var(--bs-gutter-x);
	}
}

#main-app {
	&.sidebar-hidden {
		.sidebar-content {
			margin-left: -110%;
			border-right: 0;

			@media screen and (min-width: $breakpoint-md) {
				margin-left: -26%;
			}
			
			@media screen and (min-width: $breakpoint-lg) {
				margin-left: -17.6666666667%;
			}
		}
		.sidebar-toggle {
			.btn {
				transform: rotate(180deg);
			}
		}
	}
}
