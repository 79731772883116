.main-navigation {
	width: 100%;
	.container-fluid {
		justify-content: initial;
	}
}

.profile-nav {
	margin-left: auto;
	margin-right: 0;
	.dropdown-menu {
		&.show {
			@media only screen and (max-width: $max-breakpoint-lg) {
				position: absolute;
				right: 0;
				left: auto;
			}
		}
	}
}

.navbar-toggler {
	margin-right: 1rem;
}
