.create-new-item {
	padding: 1rem 0;
	z-index: 4;
	&__form {
		margin: 0 auto;
		
	}
	&__submit {
		.btn {
			width: 45px;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 0;
			padding: 0;
			margin: 0 auto;
			@media screen and (min-width: $breakpoint-lg) {
				margin: 0 auto;
			}
		}
	}
}
