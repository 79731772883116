.modal {
	&-header {
		h5 {
			font-size: 0.875rem;
			text-transform: uppercase;
		}
	}
	&-body {
		padding: 0;
	}
	&__agenda {
		align-items: flex-start;
		background-color: $white;
		background-clip: padding-box;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 0.3rem;
		outline: 0;
		
		flex-flow: row wrap;
		overflow: hidden;
		@media screen and (min-width: $breakpoint-lg) {
			flex-flow: initial;
			overflow: initial;
		}
		
		&-content {
			overflow-y: auto;
			min-height: 0;
			padding: 1rem 1.25rem;
			&__header {
				display: flex;
				align-items: flex-end;
				font-size: 0.875rem;
				text-transform: uppercase;
				line-height: 1.5;
				h5 {
					font-size: 0.875rem;
				}
				&__added-by {
					line-height: 1.5;
					margin-top: 0;
					margin-bottom: 0;
					margin-left: .5rem;
					padding-left: .5rem;
					border-left: 1px solid $very-light-gray;
					&__name {
						font-weight: 600;
					}
				}
			}
			&__body {
			
			}
		}
		&-comments {
			&-container {
				display: flex;
				flex-direction: column;
				height: 100%;
				border-top-right-radius: 0.3rem;
				border-bottom-right-radius: 0.3rem;
				border-left: 1px solid $light-gray !important;
				&__header {
					display: none;
					flex-flow:       row nowrap;
					align-items:     center;
					justify-content: space-between;
					padding: 1rem 1.25rem;
					@media screen and (min-width: $breakpoint-lg) {
						display:         flex;
					}
					h5 {
						text-transform: none;
					}
				}
				&__body {
					padding: 1rem 1.25rem;
					flex-grow: 1;
				}
				&__footer {
					padding: 1rem 1.25rem;
					border-top:       1px solid $light-gray;
					position:         relative;
					background-color: $white;
				}
			}
		}
		
		.modal-content {
			background-color: transparent;
			border: none;
			border-radius: 0;
			height: 100%;
		}
		.tab-content {
			overflow: hidden;
			height: 100%;
		}
		&-close {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}
	}
	&-tabs {
		display: flex;
		flex-flow: row nowrap;
		@media scren and (min-width: $breakpoint-lg) {
			padding-top: 1.25rem;
		}
		.nav {
			border-bottom: 1px solid $light-gray;
			padding: 13px 64px 0 24px;
			//padding-left: var(--bs-gutter-x);
			//padding-right: var(--bs-gutter-x);
		}
		.nav-link {
			border: 0;
			background-color: transparent;
			padding: 6px 0 12px;
			position: relative;
			a {
				text-decoration: none;
				color: $text-gray;
				position: relative;
				&.active {
					background-color: transparent;
					color: $text-gray;
					&:after {
						content: '';
						display: block;
						position: absolute;
						bottom: -14px;
						left: 0;
						width: 100%;
						height: 4px;
						background-color: var(--bs-primary);
					}
				}
			}
			&:not(:last-child) {
				margin-right: 32px;
			}
		}
		&__block {
			flex: 0 0 100%;
			@media screen and (min-width: $breakpoint-lg) {
				flex: 6;
			}
		}
	}
	
	.spacer {
		margin: 0 0.375rem;
		color: $gray;
	}
	.list-group-item__last {
		margin-bottom: 1rem;
	}

}

.agenda-status {
	&__text {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		i {
			margin-right: .5rem;
		}
	}
}

.comment-list {
	&__date-group {
		font-size: 11px;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
		margin: 24px;
		position: relative;
		color: $gray;

		span {
			position: relative;
			background-color: $very-light-gray;
			z-index: 3;
			padding: 0px 30px;
		}
		&:after {
			position: absolute;
			top: 50%;
			left: 0px;
			width: 100%;
			content: "";
			z-index: 2;
			height: 1px;
			background-color: $gray;
		}
	}
}

.comment-item {
	display: flex;
	flex-flow: row wrap;

	&__container {
		display: flex;
		max-width: 80%;
		flex: 0 1 auto;
	}

	&__comment {
		margin-bottom: 1rem;
		position: relative;
		text-align: left;
		padding: 0.5rem 0.75rem;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 100%;
		word-break: break-word;
		font-size: 0.9rem;
		p {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&--current-user-authored {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		&__container {
			justify-content: flex-end;
			text-align: right;
		}

		.comment-item__comment {
			background: var(--bs-primary);
			color: rgb(255, 255, 255);
		}
	}

	&--other-user-authored {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&__container {
			flex-direction: column;
		}

		.comment-item__comment {
			background: $extreme-light-gray;
			display: flex;
			flex-direction: column;
			align-self: flex-start;
		}
	}
}
