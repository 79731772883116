.fade-enter-active  {
	transition: opacity 1s ease;
}
.fade-leave-active {
	transition: opacity .25s ease;
}

.fade-enter-from, .fade-leave-to {
	opacity: 0;
}
